/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { styled, useDripsyTheme, useSx } from 'dripsy';
import { useDynamicAnimation, MotiText } from 'moti';
import { MotiPressable } from 'moti/interactions';
import darken from '@utils/darken';
import useThemeColor from '@hooks/useThemeColor';
import CustomPropTypes from '@utils/CustomPropTypes';
import ActivityIndicator from '@ui/atoms/ActivityIndicator';
import View from '@ui/atoms/View';
import { FormattedMessage } from 'react-intl';

const DripsyMotiPressable = styled(MotiPressable, {
  customProps: {
    animate: true,
  },
})();

const COLORS = {
  white: {
    main: '$common.white',
    contrast: '$text.primary',
  },
  grey: {
    main: '$grey.800',
    contrast: '$common.white',
  },
  primary: {
    main: '$primary.main',
    contrast: '$common.white',
  },
  secondary: {
    main: '$secondary.main',
    contrast: '$common.white',
  },
  red: {
    main: '$red.dark',
    contrast: '$common.white',
  },
};

const SIZES = {
  small: 32,
  medium: 48,
};

function Button({ title, variant, color, size, startIcon, endIcon, onPress, loading, disabled, style, ...props }) {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  const colors = COLORS[color];
  const mainColor = useThemeColor(colors.main);
  const contrastColor = useThemeColor(colors.contrast);
  const inactive = disabled || loading;

  let borderColor = mainColor;
  let backgroundColor = 'outlined' === variant ? 'transparent' : mainColor;
  let hoverBackground = 'outlined' === variant ? mainColor : darken(mainColor, 10);
  let textColor = 'outlined' === variant ? mainColor : contrastColor;

  if (inactive) {
    backgroundColor = darken(mainColor, 10);
    borderColor = backgroundColor;
    hoverBackground = backgroundColor;
    textColor = contrastColor;
  }

  const animation = useDynamicAnimation(() => ({ color: textColor }));

  const onEnter = () => animation.animateTo({ color: contrastColor });
  const onExit = () => animation.animateTo({ color: textColor });

  return (
    <DripsyMotiPressable
      onPress={inactive ? undefined : onPress}
      style={[style, { borderColor, height: SIZES[size] }]}
      onHoverIn={onEnter}
      onHoverOut={onExit}
      onPressIn={onEnter}
      onPressOut={onExit}
      animate={({ hovered, pressed }) => {
        'worklet';

        return {
          backgroundColor: hovered || pressed ? hoverBackground : backgroundColor,
        };
      }}
      {...props}
    >
      {loading ? (
        <>
          <View sx={{ mr: '$2' }}>
            <ActivityIndicator size="small" color={textColor} />
          </View>
          <MotiText state={animation} style={sx({ px: '$1', ...theme.text.button })}>
            <FormattedMessage id="common.loading" />
          </MotiText>
        </>
      ) : (
        <>
          {startIcon && <View sx={{ mr: '$2' }}>{startIcon}</View>}
          <MotiText state={animation} style={sx({ px: '$1', ...theme.text.button })}>
            {title}
          </MotiText>
          {endIcon && <View sx={{ ml: '$2' }}>{endIcon}</View>}
        </>
      )}
    </DripsyMotiPressable>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  color: PropTypes.oneOf(['white', 'primary', 'secondary', 'grey', 'red']),
  size: PropTypes.oneOf(['small', 'medium']),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  style: CustomPropTypes.style,
};

Button.defaultProps = {
  variant: 'contained',
  color: 'white',
  size: 'medium',
  startIcon: undefined,
  endIcon: undefined,
  onPress: undefined,
  disabled: false,
  loading: false,
  style: undefined,
};

export default styled(Button, {
  defaultVariant: 'contained',
  themeKey: 'button',
})();
