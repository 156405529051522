import { useSx } from 'dripsy';
import NativeIonicons from '@expo/vector-icons/Ionicons';
import CustomPropTypes from '@utils/CustomPropTypes';

function Ionicons({ sx, ...props }) {
  const sxFactory = useSx();

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NativeIonicons {...props} style={sx ? sxFactory(sx) : undefined} />;
}

Ionicons.propTypes = {
  sx: CustomPropTypes.style,
};

Ionicons.defaultProps = {
  sx: undefined,
};

export default Ionicons;
