import 'expo/build/Expo.fx';
import { activateKeepAwake } from 'expo-keep-awake';
import * as Sentry from 'sentry-expo';
import registerRootComponent from 'expo/build/launch/registerRootComponent';
import './src/polyfills/intl';
import { createRoot } from 'react-dom/client';
import { createElement } from 'react';
import { Platform } from 'react-native';
import App from './src/App';

// @see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (process.browser) {
  // eslint-disable-next-line no-underscore-dangle
  window._frameTimestamp = null;
}

if (__DEV__) {
  activateKeepAwake();
  global.XMLHttpRequest = global.originalXMLHttpRequest || global.XMLHttpRequest;
}

Sentry.init({
  dsn: 'https://67c58ce071af454a8bb358708bcabe2b@o303238.ingest.sentry.io/6574558',
  enableInExpoDevelopment: false,
  debug: false,
});

// @see https://github.com/expo/expo/issues/18485
if ('web' === Platform.OS) {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  rootTag.render(createElement(App));
} else {
  registerRootComponent(App);
}
