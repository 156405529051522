export default {
  apiBaseUrl: 'https://api.staging.kunagi.fr',
  gtu: 'https://www.kunagi.fr/cgu-mentions-legales-dpa',
  privacy: 'https://www.kunagi.fr/politique-de-confidentialite',
  notifications: {
    experienceId: '@yieldstudio/homemeethome-staging',
  },
  login: {
    email: '',
    password: '',
  },
  pusher: {
    host: 'socket.staging.kunagi.fr',
    port: 443,
    key: 'JqorLGknii0uo5CK3PSlHX2uPJF7VVWR',
    enabledTransports: ['ws', 'wss'],
    forceTLS: true,
  },
};
