import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';
import RESIDENCE_TYPE from '@constants/ResidenceType';

const POST_TYPE = {
  classic: 'classic',
  alert: 'alert',
  document: 'document',
  repost: 'repost',
  news: 'news',
  discussion: 'discussion',
};

export function isKnownPostType(type) {
  return Object.values(POST_TYPE).includes(type);
}

export function hasCategory(type) {
  return [POST_TYPE.classic, POST_TYPE.alert].includes(type);
}

export function getPostTypeMaxLength(type) {
  switch (type) {
    case POST_TYPE.document:
    case POST_TYPE.news:
      return 1600;
    default:
      return 800;
  }
}

export function getPublishablePostTypes(role, residenceType) {
  if (RESIDENCE_TYPE.project === residenceType) {
    return [POST_TYPE.document, POST_TYPE.news];
  }

  return (
    Object.values(POST_TYPE)
      // Can't manually publish with this type
      .filter((type) => type !== POST_TYPE.repost)
      .filter((type) => {
        if (role === RESIDENCE_USER_ROLE.resident) {
          // Resident can't publish in this types
          return ![POST_TYPE.document, POST_TYPE.news].includes(type);
        }

        return true;
      })
  );
}

export function getVisiblePostTypes(residenceType) {
  if (RESIDENCE_TYPE.project === residenceType) {
    return [POST_TYPE.document, POST_TYPE.news];
  }

  return Object.values(POST_TYPE);
}

export default POST_TYPE;
