import { FlatList, Flex, Ionicons, Pressable, Text, View } from '@ui/atoms';
import { FormattedMessage } from 'react-intl';
import { Panel, Neighbor } from '@ui/molecules';
import useNeighborsQuery from '@hooks/queries/useNeighborsQuery';
import { useNavigation } from '@react-navigation/native';
import NeighborSkeleton from '@ui/molecules/NeighborSkeleton';
import SCREENS from '@constants/Screens';
import RESIDENCE_USER_ROLE from '@constants/ResidenceUserRole';

const emptyArray = [];

function ContactsWidget() {
  const { navigate } = useNavigation();

  const {
    data: neighbors,
    isFetching,
    isFetched,
  } = useNeighborsQuery({
    placeholderData: emptyArray,
    select: (data) => data?.items ?? emptyArray,
  });

  const residents = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.resident);
  const animators = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.animator);
  const managers = neighbors.filter((neighbor) => neighbor.role === RESIDENCE_USER_ROLE.manager);

  return (
    <Panel shadow>
      <View>
        <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="ui.ContactsWidget.neighbors" />
        </Text>
        {isFetching ? (
          <View>
            <NeighborSkeleton sx={{ mt: '$3' }} />
            <NeighborSkeleton sx={{ mt: '$3' }} />
          </View>
        ) : (
          <FlatList
            data={residents.slice(0, 2)}
            sx={{ flexGrow: 0 }}
            renderItem={({ item }) => <Neighbor key={item.id} contact={item} sx={{ mt: '$3' }} />}
            keyExtractor={(item) => item.id}
          />
        )}
        {isFetched && 2 < neighbors.length && (
          <Pressable onPress={() => navigate(SCREENS.neighbors)}>
            <Flex alignItems="center" sx={{ mt: '$3' }}>
              <Text>
                <FormattedMessage id="ui.ContactsWidget.showMore" />
              </Text>
              <Ionicons name="arrow-forward" sx={{ ml: '$1', color: '$text.primary' }} />
            </Flex>
          </Pressable>
        )}
      </View>

      <View sx={{ mt: '$6' }}>
        <Text variant="subtitle2" sx={{ color: '$primary.main' }}>
          <FormattedMessage id="ui.ContactsWidget.animators" />
        </Text>
        {isFetching ? (
          <NeighborSkeleton sx={{ mt: '$3' }} />
        ) : (
          <FlatList
            data={animators.slice(0, 2)}
            sx={{ flexGrow: 0 }}
            renderItem={({ item }) => <Neighbor key={item.id} contact={item} sx={{ mt: '$3' }} />}
            keyExtractor={(item) => item.id}
          />
        )}
        {isFetched && 2 < animators.length && (
          <Pressable onPress={() => navigate(SCREENS.neighbors)}>
            <Flex alignItems="center" sx={{ mt: '$3' }}>
              <Text>
                <FormattedMessage id="ui.ContactsWidget.showMore" />
              </Text>
              <Ionicons name="arrow-forward" sx={{ ml: '$1', color: '$text.primary' }} />
            </Flex>
          </Pressable>
        )}
      </View>

      <View sx={{ mt: '$6' }}>
        <Text variant="subtitle2" sx={{ color: '$primary.main', mb: '$2' }}>
          <FormattedMessage id="common.name" />
        </Text>
        {isFetching ? (
          <NeighborSkeleton sx={{ mt: '$3' }} />
        ) : (
          <FlatList
            data={managers.slice(0, 2)}
            sx={{ flexGrow: 0 }}
            renderItem={({ item }) => <Neighbor key={item.id} contact={item} sx={{ mt: '$3' }} />}
            keyExtractor={(item) => item.id}
          />
        )}
        {isFetched && 2 < managers.length && (
          <Pressable onPress={() => navigate(SCREENS.neighbors)}>
            <Flex alignItems="center" sx={{ mt: '$3' }}>
              <Text>
                <FormattedMessage id="ui.ContactsWidget.showMore" />
              </Text>
              <Ionicons name="arrow-forward" sx={{ ml: '$1', color: '$text.primary' }} />
            </Flex>
          </Pressable>
        )}
      </View>
    </Panel>
  );
}

export default ContactsWidget;
