import { useEffect, useRef } from 'react';
import useForceRender from '@hooks/useForceRender';
import PropTypes from 'prop-types';
import CustomPropTypes from '@utils/CustomPropTypes';
import getImageSize from '@utils/getImageSize';
import Image from './Image';

function ScaledImage({ source, width: passedWidth, height: passedHeight, sx, ...props }) {
  const forceRender = useForceRender();

  const loading = useRef(false);
  const width = useRef(passedWidth);
  const height = useRef(passedHeight);

  useEffect(() => {
    if (passedHeight && passedWidth) {
      return;
    }

    if (!passedHeight && !passedWidth) {
      // eslint-disable-next-line no-console
      console.error('[ScaledImage] You need to give at least a display height or width');
    }

    getImageSize(source)
      .then(({ width: originalWidth, height: originalHeight }) => {
        loading.current = true;

        if (passedWidth && !passedHeight) {
          height.current = (passedWidth * originalHeight) / originalWidth;
          width.current = passedWidth;
        } else {
          width.current = (passedHeight * originalWidth) / originalHeight;
          height.current = passedHeight;
        }

        forceRender();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('[ScaledImage] getSize failed with error: ', err);
      });
  }, [passedHeight, passedWidth, forceRender, source]);

  if (!loading.current) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Image source={source} sx={{ height: height.current, width: width.current, ...sx }} {...props} />;
}

ScaledImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  source: PropTypes.any.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  sx: CustomPropTypes.style,
};

ScaledImage.defaultProps = {
  height: undefined,
  width: undefined,
  sx: undefined,
};

export default ScaledImage;
